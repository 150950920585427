<template>
  <div
    v-if="shout && showShout"
    class="ShoutWrap"
    :class="shout.category">
    <div
      class="ShoutContainer"
      :class="{'Dismissable': shout.dismissable}">
      <h4>{{ shout.title }}</h4>
      {{ shout.message }}
      <a
        v-if="shout.link"
        :href="shout.link"
        target="_blank"
        class="Button--text">
        {{ shout.linkName || shout.link }}
      </a>
      <a
        v-if="shout.dismissable"
        class="HideShout"
        @click="hideShout">
        ×
      </a>
    </div>
  </div>
</template>

<script>
import ShoutApi from '@/api/ShoutApi'

export default {
  name: 'shout',
  props: {
    shoutType: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      onlineRestaurantShout: null,
      cateringShout: null,
      shoutHideTime: null
    }
  },
  computed: {
    shout() {
      if (this.cateringShout && this.cateringShout.businessUnit === 'catering') {
        return this.cateringShout
      }
      if (this.onlineRestaurantShout && this.onlineRestaurantShout.businessUnit === 'online_restaurant') {
        return this.onlineRestaurantShout
      }
      return this.cateringShout || this.onlineRestaurantShout
    },
    showShout() {
      if (this.shoutHideTime) {
        return new Date(this.shout.updatedAt) > new Date(this.shoutHideTime)
      }
      return true
    }
  },
  created() {
    this.getShouts()
    this.shoutHideTime = window.sessionStorage.getItem(process.env.VUE_APP_STORAGE_KEY + '/shoutHideTime')
  },
  methods: {
    async getShouts() {
      if (this.shoutType !== 'online_restaurant') {
        this.cateringShout = await this.getShout('catering')
      }
      if (this.shoutType !== 'catering') {
        this.onlineRestaurantShout = await this.getShout('online_restaurant')
      }
    },
    async getShout(businessUnit) {
      try {
        const result = (await ShoutApi.getShout(businessUnit))
        return result.currentShout
      } catch(e) {
        console.log('get shout failed', e)
        return null
      }
    },
    hideShout() {
      const hideTime = new Date().toISOString()
      window.sessionStorage.setItem(process.env.VUE_APP_STORAGE_KEY + '/shoutHideTime', hideTime)
      this.shoutHideTime = hideTime
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/base';
@import '~@/assets/css/_shared_variables.sass';

.ShoutWrap {
  width: 100%;
  padding: 0.5rem 0;
  color: $gray-darker;
  text-align: center;

  .ShoutContainer {
    font-size: 0.875rem;
    margin: auto;
    max-width: $row-width;

    @media #{$small-only} {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }
    @media #{$medium-up} {
      width: 83.33333%;
    }
    @media #{$large-up} {
      width: 66.66667%;
    }
    &.Dismissable {
      @media #{$small-only} {
        padding-right: 30px;
      }
    }
  }
  h4 {
    display: inline-block;
    margin-right: 0.25rem;
    font-weight: bold;
    color: $ink;
  }
  .Button--text {
    color: $white;
  }
  .HideShout {
    position: absolute;
    top: 4px;
    margin: 0;
    padding: 0;
    right: 10px;
    line-height: 34px;
    font-size: 34px;
    color: $white;
    text-decoration: none;
    cursor: pointer;
  }
  &.notice {
    background: $purple;
  }
  &.warning {
    background: $yellow;

    a {
      color: $gray-darker;
      font-weight: bold;
    }
  }
  &.alert {
    background: $red;
  }
  &.perk {
    background: $orange;
  }
  &.announcement {
    background: $blue;
  }
  &.notice,
  &.alert,
  &.perk,
  &.announcement {
    &, h4 {
      color: $white;
    }
  }
}
</style>
